/* eslint-disable no-console */
import { getCurrentUser } from "../utils/authentication";
import API from "./index";

export default {
  async getUserDomains(params, cb) {
    const user = getCurrentUser();
    const user_id = user && user.data_token.id;
    const response = await API.GET(`users/${user_id}/domains`, params);
    cb(response);
  },
  async getAdminDomain(params, cb) {
    const response = await API.GET("domains", params);
    cb(response);
  },
  async createDomain(body) {
    return await API.POST("domains", body);
  },
  async updateDomain(id, body) {
    return await API.PUT(`domains/${id}`, body);
  },
  async deleteDomain(item) {
    return await API.DELETE(`domains/${item.id}`);
  },
};
