var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"hasFeedback":"","label":"Mã nhân viên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            rules: [
              {
                required: true,
                message: 'Mã nhân viên không được để trống!',
              } ],
          } ]),expression:"[\n          'username',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Mã nhân viên không được để trống!',\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","placeholder":"Nhập mã nhân viên","autocomplete":"username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"send-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Gửi mã xác nhận ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }