import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import Antd from "ant-design-vue/es";
import Vuex from "vuex";
import router from "./router";
import store from "./store";
import ACL from "./utils/acl";
import CKEditor from "ckeditor4-vue";
import VueParticles from "vue-particles";
import Router from "vue-router";
import "ant-design-vue/dist/antd.less";
import "es6-promise/auto";

Vue.use(Router);
Vue.use(ACL, {
  // job: 'Web Dev'
});
Vue.use(VueParticles);
Vue.use(Antd, CKEditor, axios, Vuex);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
