// User Screen
import UserDashboard from "../views/user/Dashboard";
// Admin Screen
import Home from "../views/admin/Home";
import AdminDomain from "../views/admin/Domain";
import Role from "../views/admin/Role";
import Rule from "../views/admin/Rules";
import User from "../views/admin/User";
import AdminUser from "../views/admin/AdminUser";
import DomainRole from "../views/admin/DomainRole";

const admin_path = "/admin";
export const user_routes = {
  main: "/",
  home: `/home`,
  login: `/login`,
  user: `/user`,
  reset_password: "/reset-password",
  dashboard: "/dashboard",
};

export const admin_routes = {
  main: admin_path,
  home: `/home`,
  login: `${admin_path}/login`,
  role: `${admin_path}/role`,
  domain_role: `${admin_path}/domain-role`,
  rule: `${admin_path}/rule`,
  user: `${admin_path}/user`,
  reset_password: `${admin_path}/reset-password`,
  domain: `${admin_path}/domain`,
  admin_user: `${admin_path}/admin-user`,
};

export const sub_menu = {
  none: { key: "" },
  // user: { key: "user_sub", title: "User", icon: "user" },
};

export const children = [
  {
    path: user_routes.dashboard,
    name: "user_dashboard",
    component: UserDashboard,
    title: "Dashboard",
    sub: sub_menu.none,
    icon: "home",
  },
];

export const admin_menu = [
  {
    path: admin_routes.home,
    name: "admin_home",
    component: Home,
    title: "Home",
    sub: sub_menu.none,
    icon: "home",
  },
  {
    path: admin_routes.domain,
    name: "admin_domain",
    component: AdminDomain,
    title: "Domain",
    sub: sub_menu.none,
    icon: "star",
  },
  {
    path: admin_routes.domain_role,
    name: "domain_role",
    component: DomainRole,
    title: "Domain Role",
    sub: sub_menu.none,
    icon: "star",
  },
  {
    path: admin_routes.role,
    name: "role",
    component: Role,
    title: "Role",
    sub: sub_menu.none,
    icon: "star",
  },
  {
    path: admin_routes.rule,
    name: "rule",
    component: Rule,
    title: "Rule",
    sub: sub_menu.none,
    icon: "star",
  },
  {
    path: admin_routes.user,
    name: "user",
    component: User,
    title: "User",
    sub: sub_menu.none,
    icon: "star",
  },
  {
    path: admin_routes.admin_user,
    name: "admin_user",
    component: AdminUser,
    title: "Admin User",
    sub: sub_menu.none,
    icon: "star",
  },
];
