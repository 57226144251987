var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add"},[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Add","onOk":"handleAdd","closable":false,"maskClosable":false}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleAdd}},[_vm._v("Add")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAdd}},[_c('a-form-item',{attrs:{"label":"Username"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [{ required: true, message: 'Username is not empty!' }],
            } ]),expression:"[\n            'username',\n            {\n              rules: [{ required: true, message: 'Username is not empty!' }],\n            },\n          ]"}],attrs:{"autocomplete":"new-user","placeholder":"username"}})],1),_c('a-form-item',{attrs:{"label":"Rules"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'rules',
            {
              rules: [{ required: false, message: 'Rules select role!' }],
            } ]),expression:"[\n            'rules',\n            {\n              rules: [{ required: false, message: 'Rules select role!' }],\n            },\n          ]"}],attrs:{"mode":"tags","placeholder":"rules"}},_vm._l((_vm.rules),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Role"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'role',
            {
              rules: [{ required: true, message: 'Role is not empty!!' }],
            } ]),expression:"[\n            'role',\n            {\n              rules: [{ required: true, message: 'Role is not empty!!' }],\n            },\n          ]"}],attrs:{"placeholder":"role"}},_vm._l((_vm.roles),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Full name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'fullname',
                {
                  rules: [
                    { required: true, message: 'Full name is not empty!!' } ],
                } ]),expression:"[\n                'fullname',\n                {\n                  rules: [\n                    { required: true, message: 'Full name is not empty!!' },\n                  ],\n                },\n              ]"}],attrs:{"autocomplete":"new-user","placeholder":"full name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  rules: [
                    { required: true, message: 'Phone is not empty!' },
                    {
                      pattern: /(09|01[2|6|8|9])+([0-9]{8})|(84)+([0-9]{9})\b/g,
                      message: 'Phone number is incorrect!',
                    } ],
                } ]),expression:"[\n                'phone',\n                {\n                  rules: [\n                    { required: true, message: 'Phone is not empty!' },\n                    {\n                      pattern: /(09|01[2|6|8|9])+([0-9]{8})|(84)+([0-9]{9})\\b/g,\n                      message: 'Phone number is incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"phone"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [
                    { required: true, message: 'Email is not empty!' },
                    {
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: 'Email is incorrect!',
                    } ],
                } ]),expression:"[\n                'email',\n                {\n                  rules: [\n                    { required: true, message: 'Email is not empty!' },\n                    {\n                      pattern: /^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$/g,\n                      message: 'Email is incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                {
                  rules: [
                    { required: true, message: 'Address is not empty!' } ],
                } ]),expression:"[\n                'address',\n                {\n                  rules: [\n                    { required: true, message: 'Address is not empty!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"address"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department',
                {
                  rules: [
                    { required: true, message: 'Department is not empty!' } ],
                } ]),expression:"[\n                'department',\n                {\n                  rules: [\n                    { required: true, message: 'Department is not empty!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"department"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Team"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'team',
                {
                  rules: [{ required: true, message: 'Team is not empty!' }],
                } ]),expression:"[\n                'team',\n                {\n                  rules: [{ required: true, message: 'Team is not empty!' }],\n                },\n              ]"}],attrs:{"placeholder":"team"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Product"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'product',
                {
                  rules: [
                    { required: true, message: 'Product is not empty!!' } ],
                } ]),expression:"[\n                'product',\n                {\n                  rules: [\n                    { required: true, message: 'Product is not empty!!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"product"}},_vm._l((_vm.products),function(product){return _c('a-select-option',{key:product.id,attrs:{"value":product.name}},[_vm._v(" "+_vm._s(product.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                  rules: [
                    { required: true, message: 'Status is not empty!!' } ],
                } ]),expression:"[\n                'status',\n                {\n                  rules: [\n                    { required: true, message: 'Status is not empty!!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"status"}},_vm._l((_vm.STATUS_LIST),function(status){return _c('a-select-option',{key:status.value,attrs:{"value":status.value}},[_vm._v(" "+_vm._s(status.text)+" ")])}),1)],1)],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }