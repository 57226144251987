import Vue from "vue";
import Vuex from "vuex";
import createLogger from "../plugins/logger";

import users from "./modules/user";
import domain from "./modules/domain";
import role from "./modules/role";
import rules from "./modules/rules";
import product from "./modules/product";
import admin_user from "./modules/admin-user";
import domain_role from "./modules/domain-role";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    users,
    domain,
    role,
    rules,
    product,
    admin_user,
    domain_role,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
