/* eslint-disable no-console */
import _ from "lodash";
import Router from "vue-router";
import { isAuthenticated, isAuthenticatedAdmin } from "../utils/authentication";
import PageNotFound from "../views/PageNotFound";

import UserMainLayout from "../views/user/Main";
import AdminMainLayout from "../views/admin/Main";

import UserLogin from "../views/user/Login";
import AdminLogin from "../views/admin/Login";

import UserResetPassword from "../views/user/ResetPassword";

import { user_routes, children, admin_routes, admin_menu } from "./routes";

export default new Router({
  mode: "history",
  routes: [
    {
      path: admin_routes.main,
      name: "admin_main",
      component: AdminMainLayout,
      redirect: admin_routes.home,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticatedAdmin()) {
          next(admin_routes.login);
        } else next();
      },
      children: admin_menu,
    },
    {
      path: admin_routes.login,
      name: "admin_login",
      component: AdminLogin,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticatedAdmin()) {
          next();
        } else next(admin_routes.main);
      },
    },
    {
      path: user_routes.main,
      name: "user_main",
      component: UserMainLayout,
      redirect: user_routes.dashboard,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated()) {
          next(user_routes.login);
        } else next();
      },
      children: children,
    },
    {
      path: user_routes.login,
      name: "user_login",
      component: UserLogin,
      beforeEnter: (to, from, next) => {
        if (!isAuthenticated()) {
          next();
        } else next(user_routes.main);
      },
    },
    {
      path: user_routes.reset_password,
      name: "user_reset_password",
      component: UserResetPassword,
    },
    {
      path: "*",
      name: "NotFound",
      component: PageNotFound,
    },
  ],
});

export function getMenuList(menus) {
  const menu = [];
  const menu_by_sub = _.groupBy(menus, (item) => item.sub.key);
  for (const [key, value] of Object.entries(menu_by_sub)) {
    if (key === "") {
      value.forEach((element) => {
        menu.push(element);
      });
    } else {
      const children = value.map((element) => {
        return element;
      });
      menu.push({
        sub_key: value[0].sub.key,
        sub_title: value[0].sub.title,
        sub_icon: value[0].sub.icon,
        children,
      });
    }
  }
  return menu;
}
