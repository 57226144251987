var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"hasFeedback":"","label":"Mã nhân viên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            initialValue: _vm.username,
            rules: [
              {
                required: true,
                message: 'Mã nhân viên không được để trống!',
              } ],
          } ]),expression:"[\n          'username',\n          {\n            initialValue: username,\n            rules: [\n              {\n                required: true,\n                message: 'Mã nhân viên không được để trống!',\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","disabled":"","placeholder":"Nhập mã nhân viên","autocomplete":"username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"hasFeedback":"","label":"Mã xác nhận"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'otp',
          {
            rules: [{ required: true, message: 'OTP không được để trống!' }],
          } ]),expression:"[\n          'otp',\n          {\n            rules: [{ required: true, message: 'OTP không được để trống!' }],\n          },\n        ]"}],attrs:{"size":"large","placeholder":"Nhập mã xác nhận","autocomplete":"current-otp"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"send-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" GỬI ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }