<script>
import LoginForm from "../../components/AdminLoginForm";
export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>

<template>
  <div class="login">
    <a-col span="5" offset="9" class="login-form">
      <LoginForm />
    </a-col>
    <vue-particles
      color="#148F77"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#D0ECE7"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  height: 100vh;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
  padding-top: 10vh;
  font-family: "Segoe UI";
}
.login h1 {
  text-align: center;
  padding-bottom: 1rem;
}
.login-form {
  position: absolute;
  top: "50%";
}
</style>
