/* eslint-disable no-console */
import { code } from "../../api";
import userApi from "../../api/user";
import { renderTableColumns, getTableColumnName } from "../../utils/help";

// initial state
const state = {
  data: [],
  fetching: false,
  error: null,
  total: 0,
  columns: [],
  table_columns: [],
};

// getters
const getters = {};

// actions
const actions = {
  getAllUsers({ commit }, params) {
    commit("GET_USER_FETCHING");
    userApi.getAllUsers(params, (response) => {
      if (response) {
        if (response.code === code.success) {
          commit("GET_USER_SUCCESS", response.data);
        } else {
          commit("GET_USER_ERROR", response);
        }
      } else {
        commit("GET_USER_ERROR", {
          message: "Không thể kết nối đến hệ thống!",
        });
      }
    });
  },
};

// mutations
const mutations = {
  GET_USER_FETCHING(state) {
    state.fetching = true;
  },
  GET_USER_ERROR(state, response) {
    state.table_columns = [];
    state.data = [];
    state.fetching = false;
    state.error = response.message;
  },
  GET_USER_SUCCESS(state, response_data) {
    const total = 0;
    state.total = total;

    let data = response_data.items;
    data.map((b, index) => {
      b["key"] = index + 1;
    }); //set key to render table
    state.data = data;

    let columns = getTableColumnName(data);
    state.table_columns = renderTableColumns(columns, true);

    state.fetching = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
