var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('img',{staticClass:"login-img",attrs:{"src":require("../../assets/images/bg.svg"),"alt":"login image"}})]),_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"login-section-wrapper"},[_c('div',{staticClass:"login-wrapper"},[_c('div',{staticClass:"brand-wrapper"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/images/eggs.svg"),"alt":"logo"}})]),_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Mã nhân viên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Mã nhân viên không được để trống!',
                      } ],
                  } ]),expression:"[\n                  'username',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Mã nhân viên không được để trống!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","placeholder":"Ví dụ: 1234","autocomplete":"username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"Mật khẩu"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Mật khẩu không được để trống!',
                      } ],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Mật khẩu không được để trống!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","placeholder":"Nhập mật khẩu","autocomplete":"current-password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'remember',
                  {
                    valuePropName: 'checked',
                    initialValue: true,
                  } ]),expression:"[\n                  'remember',\n                  {\n                    valuePropName: 'checked',\n                    initialValue: true,\n                  },\n                ]"}]},[_vm._v("Remember password")])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" ĐĂNG NHẬP ")])],1)],1),_c('div',{staticClass:"forgot-password"},[_c('a',{attrs:{"href":_vm.user_routes.reset_password}},[_vm._v(" Quên mật khẩu ? ")])])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }