<script>
/* eslint-disable no-console */
import { logoutAdmin, getCurrentUserAdmin } from "../../utils/authentication";
import { admin_menu, sub_menu, admin_routes } from "../../router/routes";
import { getMenuList } from "../../router/index";
import ChangePassword from "../../components/AdminChangePassword";

export default {
  components: { ChangePassword },
  created() {
    this.handleOpenKeys();
  },
  data() {
    return {
      admin_menu,
      sub_menu,
      menu_list: getMenuList(admin_menu),
      collapsed: false,
      user: getCurrentUserAdmin(),
      currentRoutePath: this.$router.currentRoute.path,
      openKeys: [],
      change_password_visible: false,
      current_menu: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.currentRoutePath = to.path;
    next();
  },
  methods: {
    comfirmLogout() {
      var router = this.$router;
      this.$confirm({
        title: "Logout",
        content: "Do you want to logout?",
        onOk() {
          return new Promise((resolve) => {
            setTimeout(resolve, 1000);
          })
            .then(() => {
              logoutAdmin();
              router.replace(admin_routes.login);
            })
            .catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    handleToggleMenu() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) this.openKeys = [];
      else {
        this.handleOpenKeys();
      }
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    handleChangPasswordVisible(visible) {
      this.change_password_visible = visible;
    },
    handleChangeUserMenu(e) {
      if (e.key === "change_password") {
        this.handleChangPasswordVisible(true);
      } else if (e.key === "logout") {
        this.comfirmLogout();
      }
    },
    handleMenuClick(e) {
      const sub = e.keyPath[e.keyPath.length - 1];
      this.openKeys = [sub];
      this.current_menu = admin_menu.find(
        (item) => item.path === this.currentRoutePath
      );
    },
    handleOpenKeys() {
      const route = admin_menu.find(
        (item) => item.path === this.currentRoutePath
      );
      if (route.sub.key !== "") {
        this.openKeys = [route.sub.key];
      }
    },
  },
};
</script>
<template>
  <div id="main">
    <a-layout>
      <a-layout-header>
        <div class="logo"><img src="../../assets/images/logo.svg" /></div>
        <div class="option-right">
          <a-dropdown>
            <a-menu slot="overlay" @click="handleChangeUserMenu">
              <a-menu-item key="change_password">
                <a-icon type="key" />
                Change Password
              </a-menu-item>
              <a-menu-item key="logout" style="color: red">
                <a-icon type="logout" />
                Logout
              </a-menu-item>
            </a-menu>

            <div class="item">
              <a-icon type="user" style="font-size: 20px;" />
            </div>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider :trigger="null" collapsible v-model="collapsed">
          <a-menu
            theme="light"
            mode="inline"
            :selectedKeys="[this.currentRoutePath]"
            :openKeys="openKeys"
            @openChange="onOpenChange"
            @click="handleMenuClick"
          >
            <template v-for="item in menu_list">
              <a-sub-menu :key="item.sub_key" v-if="item.children">
                <span slot="title">
                  <a-icon :type="item.sub_icon" />
                  <span>{{ item.sub_title }}</span>
                </span>
                <template v-for="chil in item.children">
                  <a-menu-item :key="chil.path">
                    <router-link :to="chil.path">
                      <a-icon :type="chil.icon" />
                      <span>{{ chil.title }}</span>
                    </router-link>
                  </a-menu-item>
                </template>
              </a-sub-menu>

              <a-menu-item :key="item.path" v-else>
                <router-link :to="item.path">
                  <a-icon :type="item.icon" />
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout-content>
          <div class="header-content">
            <div class="trigger">
              <a-icon
                :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                @click="handleToggleMenu"
                style="font-size: 20px;"
              />
            </div>
            <div class="title">{{ current_menu.title }}</div>
          </div>

          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-layout>
      <ChangePassword
        :visible="change_password_visible"
        :handleVisible="handleChangPasswordVisible"
      />
    </a-layout>
  </div>
</template>
<style>
#main {
  height: 100vh;
}
#main .ant-layout-header {
  background: #021244;
  max-height: 50px;
  height: 50px;
  padding: 0;
  line-height: 0;
}
#main .ant-layout-content .header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#main .ant-layout-content .header-content .trigger {
  margin-right: 12px;
}
#main .ant-layout-content .header-content .title {
  font-size: 20px;
}
#main .ant-layout-header .logo img {
  width: 65px;
  margin: 10px 15px;
}
#main .ant-layout-header .option-right {
  position: fixed;
  display: flex;
  right: 20px;
  top: 0px;
  color: #fff;
  flex-direction: row;
}
#main .ant-layout-header .option-right .item {
  padding: 15px 12px;
}
#main .ant-layout-sider {
  background: #fff;
  height: calc(100vh - 50px);
  border-right: 1px solid #e8e8e8;
}
#main .ant-layout-sider .ant-menu-inline {
  border-right: 0;
}
#main .ant-layout-content {
  background: #fff;
  padding: 16px;
}
#main > .ant-layout {
  margin-bottom: 48px;
}
#main > .ant-layout:last-child {
  margin: 0;
}
</style>
