<script>
/* eslint-disable no-console */
import { mapState } from "vuex";
import { GET_USERS } from "../../store/actions";
import Add from "../../components/User/Add";
import Update from "../../components/User/Update";
import SeachForm from "../../components/User/SeachForm.vue";
import userApi from "../../api/user";

export default {
  created() {
    this.$store.dispatch(GET_USERS);
  },
  components: {
    Add,
    Update,
    SeachForm,
  },
  computed: {
    ...mapState({
      data: (state) => state.users.data,
      fetching: (state) => state.users.fetching,
      error: (state) => state.users.error,
      table_columns: (state) => state.users.table_columns,
    }),
  },
  data() {
    return {
      add_visible: false,
      update_visible: false,
      selected_item: {},
    };
  },
  methods: {
    handleAddVisible(visible) {
      this.add_visible = visible;
    },
    handleUpdateVisible(visible, item) {
      this.update_visible = visible;
      if (visible) this.selected_item = item;
      else this.selected_item = {};
    },
    async handleDeleteVisible(item) {
      const $store = this.$store;
      const $message = this.$message;

      this.$confirm({
        title: "Confirm",
        content: "Do you want to delete?",
        async onOk() {
          const response = await userApi.deleteUser(item.id);
          if (response) {
            $message.success("Delete successfully");
            $store.dispatch(GET_USERS);
          }
        },

        onCancel() {},
      });
    },
  },
};
</script>
<template>
  <div>
    <SeachForm />
    <div class="admin-create-button-container">
      <a-tooltip placement="topLeft">
        <template slot="title">
          <span>Create</span>
        </template>
        <a-button
          class="admin-create-button"
          type="primary"
          icon="plus"
          @click="() => handleAddVisible(true)"
          >Create</a-button
        >
      </a-tooltip>
    </div>
    <a-table
      :columns="table_columns"
      :data-source="data"
      :loading="fetching"
      :scroll="{ x: 1500, y: 0 }"
    >
      <a slot="user_action" href="javascript:;" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>Edit</span>
          </template>
          <a-icon
            type="edit"
            @click="handleUpdateVisible(true, record)"
            style="color: black"
            theme="filled"
          />
        </a-tooltip>
        <a-divider type="vertical" slot="action" />
        <a-tooltip placement="top">
          <template slot="title">
            <span>Delete</span>
          </template>
          <a-icon
            type="delete"
            @click="handleDeleteVisible(record)"
            theme="filled"
            style="color: red"
          />
        </a-tooltip>
      </a>
    </a-table>
    <Add v-model="add_visible" :handleVisible="handleAddVisible" />
    <Update
      v-model="update_visible"
      :handleVisible="handleUpdateVisible"
      :selected_item="selected_item"
    />
  </div>
</template>
