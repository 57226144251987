var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add"},[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Create","onOk":"handleAdd","closable":false,"maskClosable":false},on:{"change":_vm.onChangeModal}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleAdd}},[_vm._v("Create")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAdd}},[_c('a-form-item',{attrs:{"label":"Username"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input username!',
                } ],
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input username!',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"username"}})],1),_c('a-form-item',{attrs:{"label":"Domain"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'domain',
            {
              rules: [{ required: true, message: 'Please select domain!' }],
            } ]),expression:"[\n            'domain',\n            {\n              rules: [{ required: true, message: 'Please select domain!' }],\n            },\n          ]"}],attrs:{"placeholder":"domain"}},_vm._l((_vm.domains),function(name){return _c('a-select-option',{key:name,attrs:{"value":name}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Product"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'product',
            {
              rules: [{ required: true, message: 'Product is not empty!!' }],
            } ]),expression:"[\n            'product',\n            {\n              rules: [{ required: true, message: 'Product is not empty!!' }],\n            },\n          ]"}],attrs:{"placeholder":"product"}},_vm._l((_vm.products),function(product){return _c('a-select-option',{key:product.id,attrs:{"value":product.name}},[_vm._v(" "+_vm._s(product.name)+" ")])}),1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }