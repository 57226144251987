<template>
  <a-result
    status="success"
    title="Thành công"
    sub-title="Đổi mật khẩu thành công, vui lòng đăng nhập lại!"
  >
    <template #extra>
      <a-button key="console" type="primary" :href="user_routes.login">
        Về trang đăng nhập
      </a-button>
    </template>
  </a-result>
</template>
<script>
import { user_routes } from "../router/routes";

export default {
  data() {
    return {
      user_routes,
    };
  },
};
</script>
