<template>
  <div class="form-container">
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item label="Mã nhân viên">
        <a-input
          size="large"
          disabled
          autocomplete="username"
          v-decorator="[
            'username',
            {
              initialValue: user_info.data_token.username,
              rules: [
                {
                  required: true,
                  message: 'Vui lòng nhập username!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Mật khẩu">
        <a-input
          size="large"
          autocomplete="current-password"
          type="password"
          placeholder="Nhập mật khẩu"
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
              ],
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="Xác nhận mật khẩu">
        <a-input
          size="large"
          autocomplete="confirm-password"
          type="password"
          placeholder="Nhập xác nhận mật khẩu"
          v-decorator="[
            'confirm_password',
            {
              rules: [
                {
                  required: true,
                  message: 'Vui lòng xác nhận mật khẩu!',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
          class="send-button"
        >
          GỬI
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
/* eslint-disable no-console */

import authApi from "../api/auth";
import { user_routes } from "../router/routes";

export default {
  props: {
    current_step: Number,
    handleCurrentSteps: Function,
    user_info: Object,
  },
  data() {
    return {
      user_routes,
      loading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (values.password !== values.confirm_password) {
            this.$message.error("Xác nhận mật khẩu không đúng!");
            return;
          }
          this.loading = true;
          const user = {
            user_id: this.user_info.data_token.id,
            access_token: this.user_info.access_token,
          };
          const body = {
            password: values.password,
            new_password: values.password,
          };
          let response = await authApi.changePassword(user, body);
          if (response) {
            console.log(response);
            this.handleCurrentSteps(this.current_step + 1);
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.form-container {
  margin: auto;
  width: 350px;
}
.form-container .ant-input {
  font-size: 14px;
}
.form-container .send-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #021244;
  border-radius: 130px;
  font-size: 16px;
  color: #fff;
  height: auto;
  border-color: #021244;
  font-weight: 600;
  margin-top: 24px;
}
</style>
