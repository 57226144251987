/* eslint-disable no-console */
let ENV_API_URL = "https://passportapidev.vetinh.com/api/v1";

const environment = process.env.VUE_APP_ENV;
if (environment === "production") {
  ENV_API_URL = "https://passportapi.vetinh.com/api/v1";
}

export let API_URL = ENV_API_URL;

export const UPLOAD_IMAGE_URL =
  "https://upload.ngocdunggroup.com.vn/upload/images?product=MA&token=yBsmex2QT3YcSsAN5WmD9A";
export const NEWS_IMAGE_MAX_SIZE_UPLOAD = 153600; // 150KB
export const MAX_SIZE_UPLOAD = 5242880;
export const AVATAR_MAX_SIZE_UPLOAD = 256000;
