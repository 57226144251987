/* eslint-disable no-console */
import API from "./index";

export default {
  async login(body) {
    return await API.POST("admin/login", body);
  },
  async getAllAdminUser(params, cb) {
    const response = await API.GET("admin", params);
    cb(response);
  },
  async createAdminUser(body) {
    return await API.POST("admin", body);
  },
  async updateAdminUser(id, body) {
    return await API.PUT(`admin/${id}`, body);
  },
  async deleteAdminUser(id) {
    return await API.DELETE(`admin/${id}`);
  },
};
