<script>
/* eslint-disable no-console */
import { logout, getCurrentUser } from "../../utils/authentication";
import { user_routes } from "../../router/routes";
import ChangePassword from "../../components/ChangePassword";

export default {
  components: { ChangePassword },
  created() {},
  data() {
    return {
      user_routes,
      collapsed: false,
      user: getCurrentUser(),
      change_password_visible: false,
      products: [{ icon: "../../assets/images/eggs.svg", name: "eggs" }],
    };
  },

  methods: {
    comfirmLogout() {
      var router = this.$router;
      this.$confirm({
        title: "Logout",
        content: "Do you want to logout?",
        onOk() {
          return new Promise((resolve) => {
            setTimeout(resolve, 1000);
          })
            .then(() => {
              logout();
              router.replace(user_routes.login);
            })
            .catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    handleChangPasswordVisible(visible) {
      this.change_password_visible = visible;
    },
    handleChangeUserMenu(e) {
      if (e.key === "change_password") {
        this.handleChangPasswordVisible(true);
      } else if (e.key === "logout") {
        this.comfirmLogout();
      }
    },
    handleChangeCompanyMenu(e) {
      console.log(e.key);
    },
  },
};
</script>
<template>
  <div class="main">
    <a-layout>
      <div class="header">
        <div class="logo">
          <a href="/"><img src="../../assets/images/logo.svg"/></a>
        </div>
        <div class="option-right">
          <a-dropdown>
            <a-menu slot="overlay" @click="handleChangeUserMenu">
              <a-menu-item key="change_password">
                <a-icon type="key" />
                Change Password
              </a-menu-item>
              <a-menu-item key="logout" style="color: red">
                <a-icon type="logout" />
                Logout
              </a-menu-item>
            </a-menu>

            <div class="item">
              <a-icon type="user" style="font-size: 20px;" />
            </div>
          </a-dropdown>
        </div>
      </div>

      <a-layout-content>
        <router-view />
      </a-layout-content>

      <ChangePassword
        :visible="change_password_visible"
        :handleVisible="handleChangPasswordVisible"
      />
    </a-layout>
  </div>
</template>
<style>
.main {
  height: 100vh;
}
.main .ant-layout {
  min-height: 100%;
  background: #f1f4f7;
}
.main .header {
  background-color: #021244;
  max-height: 50px;
  position: relative;
}
.main .header .logo img {
  width: 65px;
  margin: 10px 15px;
}
.main .header .option-right {
  position: absolute;
  display: flex;
  right: 20px;
  top: 0px;
  color: #fff;
  flex-direction: row;
}
.main .header .option-right .item {
  padding: 15px 12px;
}
.overlay-container {
  flex-direction: row;
  display: flex;
  background-color: #fff;
  padding: 16px;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.overlay-container .content {
  padding: 10px 18px;
}
.overlay-container .content img {
  height: 34px;
  width: 70px;
  cursor: pointer;
}
</style>
