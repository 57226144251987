/* eslint-disable no-console */
import API from "./index";

export default {
  async getAllUsers(params, cb) {
    const response = await API.GET("users", params);
    cb(response);
  },
  async createUser(body) {
    return await API.POST("users", body);
  },
  async updateUser(id, body) {
    return await API.PUT(`users/${id}`, body);
  },
  async deleteUser(id) {
    return await API.DELETE(`users/${id}`);
  },
};
