<template>
  <div class="updateEvent">
    <a-modal
      :visible="visible"
      title="Đổi mật khẩu"
      onOk="handleChangePassword"
      :closable="false"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Hủy</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleChangePassword"
          >Cập nhật</a-button
        >
      </template>

      <a-form :form="form" @submit="handleChangePassword">
        <a-form-item label="Tên người dùng">
          <a-input
            disabled
            autocomplete="change_pass_email"
            v-decorator="[
              'email',
              {
                initialValue: user && user.username,
                rules: [
                  {
                    required: true,
                    message: 'Tên người dùng không để trống!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Mật khẩu">
          <a-input
            autocomplete="current-password"
            type="password"
            placeholder="Nhập mật khẩu"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng nhập mật khẩu!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="Mật khẩu mới">
          <a-input
            autocomplete="new-password"
            type="password"
            placeholder="Nhập mật khẩu mới"
            v-decorator="[
              'new_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng nhập mật khẩu mới!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Xác nhận mật khẩu">
          <a-input
            autocomplete="confirm-password"
            type="password"
            placeholder="Nhập xác nhận mật khẩu"
            v-decorator="[
              'confirm_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Vui lòng xác nhận mật khẩu!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import authApi from "../api/auth";
import { mapState } from "vuex";
import { getCurrentUser } from "../utils/authentication";

export default {
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: "add_form" }),
      user: getCurrentUser().data_token,
    };
  },

  computed: {
    ...mapState({
      rules: (state) => state.rules.data,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleVisible: Function,
  },
  methods: {
    onCancel() {
      this.handleVisible(false);
      this.form.resetFields();
    },
    handleChangePassword(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (values.new_password !== values.confirm_password) {
            this.$message.error("Xác nhận mật khẩu không đúng!");
            return;
          }
          this.loading = true;
          let body = {
            password: values.password,
            new_password: values.new_password,
          };
          const response = await authApi.changePassword(body);

          if (response) {
            this.$message.success("Change password successfuly");
            this.onCancel();
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
