<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import {
  GET_ROLES,
  GET_RULES,
  GET_PRODUCT,
  GET_ADMIN_DOMAIN,
} from "./store/actions";
import { getCurrentUserAdmin } from "./utils/authentication";

export default {
  name: "app",
  created() {
    const user = getCurrentUserAdmin();
    const { pathname } = window.location;
    if (user && pathname.includes("/admin")) {
      this.$store.dispatch(GET_ROLES);
      this.$store.dispatch(GET_RULES);
      this.$store.dispatch(GET_PRODUCT);
      this.$store.dispatch(GET_ADMIN_DOMAIN);
    }
  },
};
</script>

<style>
@import "./assets/css/styles.css";
</style>
