<template>
  <div class="updateEvent">
    <a-modal
      :visible="visible"
      title="Update"
      onOk="handleUpdate"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdate"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleUpdate">
        <a-form-item label="Name">
          <a-input
            placeholder="name"
            v-decorator="[
              'name',
              {
                initialValue: selected_item.name,
                rules: [
                  {
                    required: true,
                    message: 'Please input name!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="Actions">
          <a-select
            v-decorator="[
              'actions',
              {
                initialValue: selected_item.actions,
                rules: [{ required: true, message: 'Please select actions!' }],
              },
            ]"
            placeholder="actions"
          >
            <a-select-option
              v-for="item in RULE_ACTIONS"
              :key="item.key"
              :value="item.key"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Subject">
          <a-select
            v-decorator="[
              'subject',
              {
                initialValue: selected_item.subject,
                rules: [{ required: true, message: 'Please select subject!' }],
              },
            ]"
            placeholder="subject"
          >
            <a-select-option
              v-for="item in RULE_SUBJECT"
              :key="item.key"
              :value="item.key"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Conditions">
          <a-textarea
            v-decorator="[
              'conditions',
              {
                initialValue: selected_item.conditions,
                rules: [
                  { required: true, message: 'Please input conditions!' },
                ],
              },
            ]"
            placeholder="conditions"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import moment from "moment";
import ruleApi from "../../api/rules";
import { GET_RULES } from "../../store/actions";
import { RULE_ACTIONS, RULE_SUBJECT } from "../../utils/constant";

export default {
  data() {
    return {
      RULE_SUBJECT,
      RULE_ACTIONS,
      moment,
      loading: false,
      form: this.$form.createForm(this, { name: "update_form" }),
    };
  },

  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleVisible: Function,
    selected_item: Object,
  },
  methods: {
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleVisible(false);
      this.form.resetFields();
    },
    handleUpdate(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            name: values.name,
            actions: values.actions,
            subject: values.subject,
            conditions: values.conditions,
          };

          const response = await ruleApi.updateRules(
            this.selected_item.name,
            body
          );
          if (response) {
            this.$message.success("Update successfully");
            this.$store.dispatch(GET_RULES);
            this.onCancel();
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
