/* eslint-disable no-console */
import API from "./index";

export default {
  async getAllRoles(params, cb) {
    const response = await API.GET("domainroles", params);
    cb(response);
  },
  async createRole(body) {
    return await API.POST("domainroles", body);
  },
  async updateRole(name, body) {
    return await API.PUT(`domainroles/${name}`, body);
  },
  async deleteRole({ data }) {
    return await API.DELETE(`domainroles`, { data });
  },
};
