<script>
export default {
  created() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
};
</script>
<template>
  <div>Home Content</div>
</template>
