/* eslint-disable no-console */
import API from "./index";

export default {
  async sendOTP(body) {
    return await API.POST("login/send_bot_otp", body);
  },
  async validationOTP(body) {
    return await API.POST("login/verify_bot_otp", body);
  },
  async changePassword(user, body) {
    const url = `users/${user.user_id}/reset_password?access_token=${user.access_token}`;
    return await API.PUT(url, body);
  },
  async login( body) {
    return await API.POST("users/login", body);
  }
};
