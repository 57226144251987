/* eslint-disable no-console */
import axios from "axios";
import { API_URL, UPLOAD_IMAGE_URL } from "../config";
import { message } from "ant-design-vue";
import {
  getCurrentUser,
  getCurrentUserAdmin,
  logout,
  logoutAdmin,
} from "../utils/authentication";

export const code = {
  success: 1,
  error: -1,
  system_error: -2,
  session_timeout: -501,
  not_found: 404,
  parse_json_error: -20,
};

function getAccessToken() {
  let user = {};
  const pathname = window.location.pathname;
  if (pathname.includes("/admin")) {
    user = getCurrentUserAdmin();
  } else {
    user = getCurrentUser();
  }
  return (user && user.access_token) || "";
}

function getUrlParams(data) {
  data.access_token = getAccessToken();
  return (
    "?" +
    Object.keys(data)
      .map((key) => `${key}=${encodeURIComponent(data[key])}`)
      .join("&")
  );
}

function showError(result) {
  if (result.data.code === code.session_timeout) {
    const text = `Đăng nhập hết hạn, hệ thống tự động đăng xuất`;
    message.loading(text, 1.5, () => {
      if (window.location.pathname.startsWith("/admin")) {
        logoutAdmin();
      } else {
        logout();
      }
    });
  } else {
    message.error(result.data.message);
  }
}
function getConfig() {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return config;
}

async function GET(path, params) {
  var url = `${API_URL}/${path}`;
  const config = getConfig();
  if (!params) params = {};

  const p = getUrlParams(params);
  url = `${API_URL}/${path}${p}`;

  try {
    const result = await axios.get(url, config);
    if (result && result.data && result.data.code === code.success) {
      return result.data;
    } else {
      showError(result);
      return result.data;
    }
  } catch (error) {
    return null;
  }
}

async function POST(path, body) {
  const url = `${API_URL}/${path}`;
  const config = getConfig();
  body.access_token = getAccessToken();

  try {
    const result = await axios.post(url, body, config);
    if (result.data && result.data.code === code.success) {
      return result.data;
    } else {
      showError(result);

      return null;
    }
  } catch (error) {
    return null;
  }
}
async function PUT(path, body) {
  const url = `${API_URL}/${path}`;
  const config = getConfig();
  body.access_token = getAccessToken();

  try {
    const result = await axios.put(url, body, config);

    if (result.data && result.data.code === code.success) {
      return result.data;
    } else {
      showError(result);
      return null;
    }
  } catch (error) {
    return null;
  }
}

async function DELETE(path, { data, params }) {
  var url = `${API_URL}/${path}`;
  const config = getConfig();
  if (!params) params = {};

  const p = getUrlParams(params);
  url = `${API_URL}/${path}${p}`;

  if (data) config["data"] = data;

  try {
    const result = await axios.delete(url, config);

    if (result.data && result.data.code === code.success) {
      return result.data;
    } else {
      showError(result);
      return null;
    }
  } catch (error) {
    return null;
  }
}

async function UPLOAD_IMAGE(file) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let image_url = "";

  const formData = new FormData();
  formData.append("file1", file, "img.jpg");

  const response = await axios.post(UPLOAD_IMAGE_URL, formData, config);

  if (
    response.data &&
    response.data.status === 1 &&
    response.data.data.length > 0
  ) {
    image_url = response.data.data[0].url;
  }
  return image_url;
}

async function SEND_OTP(body) {
  const url = `${API_URL}`;
  const config = getConfig();

  try {
    const result = await axios.post(url, body, config);
    if (result.data && result.data.status === code.success) {
      return result.data;
    }
  } catch (error) {
    return null;
  }
}
const API = {
  GET,
  POST,
  PUT,
  DELETE,
  UPLOAD_IMAGE,
  SEND_OTP,
};
export default API;
