/* eslint-disable no-console */
import API from "./index";

export default {
  async getAllRules(params, cb) {
    const response = await API.GET("rules", params);
    cb(response);
  },
  async createRule(body) {
    return await API.POST("rules", body);
  },
  async updateRules(name, body) {
    return await API.PUT(`rules/${name}`, body);
  },
  async deleteRules(name) {
    return await API.DELETE(`rules/${name}`);
  },
};
