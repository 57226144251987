<script>
/* eslint-disable no-console */
import { mapState } from "vuex";
import { GET_USER_DOMAIN } from "../../store/actions";
import { getCurrentUser } from "../../utils/authentication";

export default {
  created() {
    this.showError();
    this.$store.dispatch(GET_USER_DOMAIN);
  },
  components: {},
  computed: {
    ...mapState({
      data: (state) => state.domain.data,
      products: (state) => state.domain.products,
      fetching: (state) => state.domain.fetching,
      error: (state) => state.domain.error,
      table_columns: (state) => state.domain.table_columns,
    }),
  },
  data() {
    return {
      user: getCurrentUser(),
    };
  },
  methods: {
    showError() {
      const { query, path } = this.$route;
      if (query.message) {
        this.$message.error(query.message, 1.5, () => {
          this.$router.replace(path);
        });
      }
    },
    renderProductText(product) {
      if (product === "ngocdung") return "Ngọc Dung";
      else if (product === "zasaly") return "Zasaly";
      else if (product === "zema") return "Zema";
      else if (product === "next") return "Nextmarketing";
      else return product;
    },
    handleClickDomain(item) {
      const username = this.user.data_token && this.user.data_token.username;
      window.open(
        `${item.url}?username=${username}&access_token=${this.user.access_token}`
      );
    },
  },
};
</script>
<template>
  <div class="dashboard">
    <div class="content" v-for="product in products" :key="product">
      <h2 class="title-moulded">{{ renderProductText(product) }}</h2>
      <a-row :gutter="24">
        <a-col
          :span="6"
          v-for="domain in data.filter((item) => item.product === product)"
          :key="domain.id"
          @click="() => handleClickDomain(domain)"
        >
          <div class="moulded">
            <a href="">
              <div class="icon"><a-icon type="shopping-cart" /></div>
              <label for="">{{ domain.name.toUpperCase() }}</label>
            </a>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<style>
.dashboard {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.dashboard .content {
  margin-top: 20px;
}
.dashboard .content .title-moulded {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #28338a;
}
.dashboard .content .moulded {
  background: white;
  border: 1px solid #e4e7eb;
  padding: 15px;
  position: relative;
  min-height: 75px;
  cursor: pointer;
  padding-left: 75px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding-top: 22px;
}
.dashboard .content .moulded:hover {
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.25);
  border: 1px solid #bbbbc1;
}
.dashboard .content .moulded a {
  display: inline-block;
  width: 100%;
}
.dashboard .content .moulded .icon {
  width: 55px;
  height: 55px;
  position: absolute;
  left: 10px;
  top: 9px;
  border-radius: 4px;
  font-size: 28px;
  color: white;
  background: #707393;
  text-align: center;
  padding-top: 6px;
}
.dashboard .content .moulded label {
  color: #1b1c31;
  display: inline-block;
  width: 100%;
  line-height: 29px;
}
.dashboard .content .ant-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {
  .dashboard {
    max-width: 540px;
  }
}
@media (min-width: 992px) {
  .dashboard {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .dashboard {
    max-width: 720px;
  }
  .dashboard .content .ant-col-6 {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .dashboard {
    max-width: 1140px;
  }
}
</style>
