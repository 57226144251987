/* eslint-disable no-console */
import API from "./index";

export default {
  async getAllProducts(params, cb) {
    const response = await API.GET("products", params);
    cb(response);
  },
  async createProducts(body) {
    return await API.POST("products", body);
  },
  async updateProducts(id, body) {
    return await API.PUT(`products/${id}`, body);
  },
  async deleteProducts(id) {
    return await API.DELETE(`products/${id}`);
  },
};
