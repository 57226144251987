<template>
  <div>
    <a-form class="search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="16">
        <a-col :span="6">
          <a-form-item label="Username">
            <a-input v-decorator="['username']" placeholder="username" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Domain">
            <a-select v-decorator="['domain']" placeholder="domain">
              <a-select-option
                v-for="name in domains"
                :key="name"
                :value="name"
              >
                {{ name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="Product">
            <a-select v-decorator="['product']" placeholder="product">
              <a-select-option
                v-for="product in products"
                :key="product.id"
                :value="product.name"
              >
                {{ product.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <div style="text-align: center">
        <a-button type="primary" html-type="submit" icon="search">
          Tìm kiếm
        </a-button>
        <a-button
          :style="{ marginLeft: '8px' }"
          @click="handleReset"
          icon="delete"
        >
          Đặt lại
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { GET_DOMAIN_ROLE } from "../../store/actions";
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "search_form" }),
    };
  },
  computed: {
    ...mapState({
      domains: (state) => {
        const data = state.domain.data.map((item) => item.name);
        return [...new Set(data)];
      },
      products: (state) => state.product.data,
    }),
  },
  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) return;
        const params = {};

        if (values.username) params.username = values.username;
        if (values.product) params.product = values.product;
        if (values.domain) params.domain = values.domain;

        this.$store.dispatch(GET_DOMAIN_ROLE, params);
      });
    },
    handleReset() {
      this.form.resetFields();
    },
  },
};
</script>

<style></style>
