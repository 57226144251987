<template>
  <div class="login_form">
    <h1>Đăng Nhập</h1>
    <a-form :form="form" class="login-form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          v-decorator="[
            'username',
            {
              rules: [
                {
                  required: true,
                  message: 'Tên đăng nhập không được để trống!',
                },
              ],
            },
          ]"
          placeholder="Tên đăng nhập"
          autocomplete="username"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input-password
          v-decorator="[
            'password',
            {
              rules: [
                { required: true, message: 'Mật khẩu không được để trống!' },
              ],
            },
          ]"
          placeholder="Mật khẩu"
          autocomplete="current-password"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>

      <a-form-item>
        <a-checkbox
          v-decorator="[
            'remember',
            {
              valuePropName: 'checked',
              initialValue: true,
            },
          ]"
          >Remember me</a-checkbox
        >
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
          class="login-form-button"
        >
          Đăng nhập
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
/* eslint-disable no-console */

import adminApi from "../api/admin";
import { admin_routes } from "../router/routes";

export default {
  data() {
    return {
      admin_routes,
      loading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            username: values.username,
            password: values.password,
            expiresIn: "30y",
          };
          let response = await adminApi.login(body);
          if (response) {
            localStorage.setItem(
              "PORTAL_ADMIN_USER",
              JSON.stringify(response.data)
            );

            setTimeout(() => {
              this.$router.replace(admin_routes.main);
              this.loading = false;
            }, 1000);
          } else {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.login-form-button {
  width: 100%;
}
.login_form h1 {
  text-align: center;
}
</style>
