/* eslint-disable no-console */
import API from "./index";

export default {
  async getAllRoles(params, cb) {
    const response = await API.GET("roles", params);
    cb(response);
  },
  async createRole(body) {
    return await API.POST("roles", body);
  },
  async updateRole(name, body) {
    return await API.PUT(`roles/${name}`, body);
  },
  async deleteRole(name) {
    return await API.DELETE(`roles/${name}`);
  },
};
