var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Mã nhân viên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            initialValue: _vm.user_info.data_token.username,
            rules: [
              {
                required: true,
                message: 'Vui lòng nhập username!',
              } ],
          } ]),expression:"[\n          'username',\n          {\n            initialValue: user_info.data_token.username,\n            rules: [\n              {\n                required: true,\n                message: 'Vui lòng nhập username!',\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","disabled":"","autocomplete":"username"}})],1),_c('a-form-item',{attrs:{"label":"Mật khẩu"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Vui lòng nhập mật khẩu!',
              } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Vui lòng nhập mật khẩu!',\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","autocomplete":"current-password","type":"password","placeholder":"Nhập mật khẩu"}})],1),_c('a-form-item',{attrs:{"label":"Xác nhận mật khẩu"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirm_password',
          {
            rules: [
              {
                required: true,
                message: 'Vui lòng xác nhận mật khẩu!',
              } ],
          } ]),expression:"[\n          'confirm_password',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Vui lòng xác nhận mật khẩu!',\n              },\n            ],\n          },\n        ]"}],attrs:{"size":"large","autocomplete":"confirm-password","type":"password","placeholder":"Nhập xác nhận mật khẩu"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"send-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" GỬI ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }