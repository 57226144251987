<template>
  <div class="add">
    <a-modal
      :visible="visible"
      title="Add"
      onOk="handleAdd"
      :closable="false"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAdd"
          >Add</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAdd">
        <a-form-item label="Username">
          <a-input
            autocomplete="new-user"
            placeholder="username"
            v-decorator="[
              'username',
              {
                rules: [{ required: true, message: 'Username is not empty!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Rules">
          <a-select
            mode="tags"
            v-decorator="[
              'rules',
              {
                rules: [{ required: false, message: 'Rules select role!' }],
              },
            ]"
            placeholder="rules"
          >
            <a-select-option
              v-for="item in rules"
              :key="item.name"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Role">
          <a-select
            v-decorator="[
              'role',
              {
                rules: [{ required: true, message: 'Role is not empty!!' }],
              },
            ]"
            placeholder="role"
          >
            <a-select-option
              v-for="item in roles"
              :key="item.name"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Full name">
              <a-input
                autocomplete="new-user"
                placeholder="full name"
                v-decorator="[
                  'fullname',
                  {
                    rules: [
                      { required: true, message: 'Full name is not empty!!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Phone">
              <a-input
                placeholder="phone"
                v-decorator="[
                  'phone',
                  {
                    rules: [
                      { required: true, message: 'Phone is not empty!' },
                      {
                        pattern:
                          /(09|01[2|6|8|9])+([0-9]{8})|(84)+([0-9]{9})\b/g,
                        message: 'Phone number is incorrect!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Email">
              <a-input
                placeholder="email"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: 'Email is not empty!' },
                      {
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Email is incorrect!',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Address">
              <a-input
                placeholder="address"
                v-decorator="[
                  'address',
                  {
                    rules: [
                      { required: true, message: 'Address is not empty!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Department">
              <a-input
                placeholder="department"
                v-decorator="[
                  'department',
                  {
                    rules: [
                      { required: true, message: 'Department is not empty!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Team">
              <a-input
                placeholder="team"
                v-decorator="[
                  'team',
                  {
                    rules: [{ required: true, message: 'Team is not empty!' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import adminApi from "../../api/admin";
import { mapState } from "vuex";
import { getCurrentUserAdmin } from "../../utils/authentication";
import { GET_ADMIN_USERS } from "../../store/actions";
import { STATUS_LIST } from "../../utils/constant";

export default {
  data() {
    return {
      STATUS_LIST,
      currentUser: getCurrentUserAdmin(),
      loading: false,
      form: this.$form.createForm(this, { name: "add_form" }),
    };
  },

  computed: mapState({
    rules: (state) => state.rules.data,
    roles: (state) => state.role.data,
  }),
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleVisible: Function,
  },
  methods: {
    onCancel() {
      this.handleVisible(false);
      this.form.resetFields();
    },
    handleAdd(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const now = new Date().getTime();
          let body = {
            code: values.username,
            fullname: values.fullname,
            username: values.username,
            password: "123456",
            email: values.email,
            address: values.address,
            team: values.team,
            department: values.department,
            role: values.roles,
            rules: values.rules,
            status: values.status,
            phone: values.phone,
            created_time: now,
            updated_time: now,
            created_by: this.currentUser.data_token.username,
          };

          const response = await adminApi.createAdminUser(body);
          if (response) {
            this.$message.success(`Add successfully`);
            this.onCancel();
            this.$store.dispatch(GET_ADMIN_USERS);
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
