/* eslint-disable no-console */
export const dateFormat = "DD/MM/YYYY  HH:mm:ss";

export function upperCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hidePhone(phone) {
  return phone && "******" + phone.slice(-4);
}

export function sortString(a, b) {
  return a.localeCompare(b);
}

export function sortDate(a, b) {
  return a - b;
}

export function dateConvert(input_time, format) {
  if (!input_time) {
    return "Ngày không hợp lệ";
  }
  const time = new Date(input_time);
  const date = time.getDate();
  const month = time.getMonth();
  const year = time.getFullYear();

  if (!format) return `${date}/${month}/${year}`;
  return `${date}/${month}/${year}`;
}

export function renderTitle(val) {
  let title = val.charAt(0).toUpperCase() + val.slice(1);
  title = title.replace(/_/g, " ");
  return title;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getIndexHasMaxKeys(array) {
  let maxIndex = 0;
  let max = Object.keys(array[0]).length;

  for (let index = 1; index < array.length; index++) {
    const element = array[index];
    const count = Object.keys(element).length;
    if (count > max) maxIndex = index;
  }

  return maxIndex;
}

export function getTableColumnName(data) {
  if (data === undefined || data.length === 0) return {};
  const index = getIndexHasMaxKeys(data); // get the most key object in array
  return data[index];
}

export function renderTableColumns(columns_name, show_action) {
  if (!columns_name) return [];
  const table_columns = []; // array columns of table
  let columns_name_array = Object.keys(columns_name);
  if (columns_name_array.length === 0) return [];

  columns_name_array = columns_name_array.filter(
    (item) => item !== "_id" && item !== "key" && item !== "__v"
  );
  table_columns.push({
    width: 100,
    title: "#",
    key: "number",
    dataIndex: "key",
    align: "center",
  });
  columns_name_array.forEach((column_name) => {
    let base = {
      title: renderTitle(column_name),
      dataIndex: column_name,
      key: column_name,
      width: 150,
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: column_name },
    };
    if (["created_time", "updated_time"].indexOf(column_name) !== -1) {
      base = {
        title: renderTitle(column_name),
        dataIndex: column_name,
        key: column_name,
        width: 150,
        align: "center",
        ellipsis: true,
        customRender: (data) => dateConvert(data),
      };
    }

    table_columns.push(base);
  });

  if (show_action)
    table_columns.push({
      // add action columns
      title: "Action",
      key: "operation",
      align: "center",
      width: 150,
      fixed: "right",
      scopedSlots: {
        customRender: "user_action",
      },
    });
  return table_columns;
}
