import { sortString } from "./help";
export const color = {
  success: "#52c41a",
  error: "f5222d",
  primary_color_1: "#c8247b",
  primary_color_2: "rgb(237, 165, 20)",
};

export const RULE_ACTIONS = [
  { key: "read", text: "Read" },
  { key: "create", text: "Create" },
  { key: "update", text: "Update" },
  { key: "delete", text: "Delete" },
].sort((a, b) => sortString(a.key, b.key));

export const RULE_SUBJECT = [
  { key: "Domain", text: "Domain" },
  { key: "User", text: "User" },
  { key: "all", text: "All" },
].sort((a, b) => sortString(a.key, b.key));
export const STATUS = {
  ACTIVE: {
    text: "Active",
    value: "active",
  },
  DEACTIVE: {
    text: "Deactive",
    value: "deactive",
  },
  WAITING_APPROVE: {
    text: "Waiting Approve",
    value: "waiting_approve",
  },
};
export const STATUS_LIST = [STATUS.ACTIVE, STATUS.DEACTIVE];
