import { getCurrentUser, ROLES } from "./authentication"

const user = getCurrentUser()
export default {
/* eslint-disable no-console */
  install (Vue) {
    // console.log(options)
    Vue.prototype.$can = function (grant, resource) {
      if(user.data_token.role !== ROLES.SUPER_ADMIN){
        // console.log(resource,grant)
        if(grant === undefined || resource === undefined)
          return false

        if(user.grant_list[`${resource}`].indexOf(grant) !== -1){
          return true
        }
        else {
          return false
        }
        
      }
      else return true

    }
  }
}
// const info = (name = 'John', age = 22, job = 'student') => `My name is ${name} and I am a ${age} years old ${job}.`

