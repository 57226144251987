<script>
import SendOTPForm from "../../components/SendOTPForm";
import ConfirmOTPForm from "../../components/ConfirmOTPForm";
import ResetPasswordForm from "../../components/ResetPasswordForm";
import ResetPasswordSuccess from "../../components/ResetPasswordSuccess";
import { user_routes } from "../../router/routes";

export default {
  name: "Login",
  components: {
    SendOTPForm,
    ConfirmOTPForm,
    ResetPasswordForm,
    ResetPasswordSuccess,
  },
  data() {
    return {
      user_routes,
      current_step: 0,
      username: "",
      user_info: {},
    };
  },
  methods: {
    handleCurrentSteps(step) {
      this.current_step = step;
    },
    getUserName(username) {
      this.username = username;
    },
    getUser(data) {
      this.user_info = data;
    },
  },
};
</script>

<template>
  <div class="reset-password">
    <div class="header">
      <div class="logo"><img src="../../assets/images/logo.svg" /></div>
    </div>
    <div class="content">
      <div class="back">
        <a-button type="link" :href="user_routes.login" icon="left">
          Quay lại
        </a-button>
      </div>
      <a-steps :current="current_step">
        <a-step title="Gửi mã xác nhận">
          <a-icon slot="icon" type="user" />
        </a-step>
        <a-step title="Xác nhận">
          <a-icon slot="icon" type="solution" />
        </a-step>
        <a-step title="Đổi mật khẩu">
          <a-icon slot="icon" type="key" />
        </a-step>
        <a-step title="Thành công">
          <a-icon slot="icon" type="smile-o" />
        </a-step>
      </a-steps>
      <div class="steps-content">
        <SendOTPForm
          v-if="current_step === 0"
          :handleCurrentSteps="handleCurrentSteps"
          :current_step="current_step"
          @getUserName="getUserName"
        />
        <ConfirmOTPForm
          v-if="current_step === 1"
          :handleCurrentSteps="handleCurrentSteps"
          :current_step="current_step"
          :username="username"
          @getUser="getUser"
        />
        <ResetPasswordForm
          v-if="current_step === 2"
          :handleCurrentSteps="handleCurrentSteps"
          :current_step="current_step"
          :user_info="user_info"
        />
        <ResetPasswordSuccess v-if="current_step === 3" />
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reset-password {
  height: 100vh;
  font-family: "Segoe UI";
}

.reset-password .header {
  background-color: #021244;
  max-height: 50px;
  position: relative;
  flex-direction: row;
  display: flex;
}
.reset-password .header .back {
  color: #fff;
  padding: 12px;
  font-size: 20px;
}
.reset-password .header .logo img {
  width: 65px;
  margin: 10px 15px;
}

.reset-password .content {
  margin: 24px;
}
.reset-password .content .back {
  margin-bottom: 24px;
}
</style>
