<template>
  <div class="form-container">
    <a-form :form="form" class="login-form" @submit="handleSubmit">
      <a-form-item hasFeedback label="Mã nhân viên">
        <a-input
          size="large"
          disabled
          v-decorator="[
            'username',
            {
              initialValue: username,
              rules: [
                {
                  required: true,
                  message: 'Mã nhân viên không được để trống!',
                },
              ],
            },
          ]"
          placeholder="Nhập mã nhân viên"
          autocomplete="username"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item hasFeedback label="Mã xác nhận">
        <a-input
          size="large"
          v-decorator="[
            'otp',
            {
              rules: [{ required: true, message: 'OTP không được để trống!' }],
            },
          ]"
          placeholder="Nhập mã xác nhận"
          autocomplete="current-otp"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
          class="send-button"
        >
          GỬI
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
/* eslint-disable no-console */

import authApi from "../api/auth";
import { user_routes } from "../router/routes";

export default {
  props: {
    current_step: Number,
    handleCurrentSteps: Function,
    username: String,
  },
  data() {
    return {
      user_routes,
      loading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            username: values.username,
            otp: values.otp,
          };
          let response = await authApi.validationOTP(body);
          if (response && response.data) {
            this.$message.success("Xác nhận thành công!");
            this.$emit("getUser", response.data);
            this.handleCurrentSteps(this.current_step + 1);
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.form-container {
  margin: auto;
  width: 350px;
}
.form-container .ant-input {
  font-size: 14px;
}
.form-container .send-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #021244;
  border-radius: 130px;
  font-size: 16px;
  color: #fff;
  height: auto;
  border-color: #021244;
  font-weight: 600;
  margin-top: 24px;
}
</style>
