var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login_form"},[_c('h1',[_vm._v("Đăng Nhập")]),_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            rules: [
              {
                required: true,
                message: 'Tên đăng nhập không được để trống!',
              } ],
          } ]),expression:"[\n          'username',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Tên đăng nhập không được để trống!',\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Tên đăng nhập","autocomplete":"username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              { required: true, message: 'Mật khẩu không được để trống!' } ],
          } ]),expression:"[\n          'password',\n          {\n            rules: [\n              { required: true, message: 'Mật khẩu không được để trống!' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Mật khẩu","autocomplete":"current-password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          } ]),expression:"[\n          'remember',\n          {\n            valuePropName: 'checked',\n            initialValue: true,\n          },\n        ]"}]},[_vm._v("Remember me")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Đăng nhập ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }