var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add"},[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"Create","onOk":"handleAdd","closable":false,"maskClosable":false}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleAdd}},[_vm._v("Create")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleAdd}},[_c('a-form-item',{attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: 'Name is not empty!' }],
            } ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: 'Name is not empty!' }],\n            },\n          ]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{attrs:{"label":"Url"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'url',
            {
              rules: [{ required: true, message: 'Url is not empty!' }],
            } ]),expression:"[\n            'url',\n            {\n              rules: [{ required: true, message: 'Url is not empty!' }],\n            },\n          ]"}],attrs:{"placeholder":"Url"}})],1),_c('a-form-item',{attrs:{"label":"Product"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'product',
            {
              rules: [{ required: true, message: 'Product is not empty!' }],
            } ]),expression:"[\n            'product',\n            {\n              rules: [{ required: true, message: 'Product is not empty!' }],\n            },\n          ]"}],attrs:{"placeholder":"Product"}},[_c('a-select-option',{key:"ngocdung",attrs:{"value":"ngocdung"}},[_vm._v(" Ngọc Dung ")])],1)],1),_c('a-form-item',{attrs:{"label":"Team"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'team',
            {
              rules: [{ required: true, message: 'Team is not empty!' }],
            } ]),expression:"[\n            'team',\n            {\n              rules: [{ required: true, message: 'Team is not empty!' }],\n            },\n          ]"}],attrs:{"placeholder":"Team"}},[_c('a-select-option',{key:"IT",attrs:{"value":"IT"}},[_vm._v(" IT ")])],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }