<script>
/* eslint-disable no-console */
import { mapState } from "vuex";
import { GET_DOMAIN_ROLE } from "../../store/actions";
import Add from "../../components/DomainRole/Add";
import domainRoleApi from "../../api/domain-role";
import SeachForm from "../../components/DomainRole/SeachForm.vue";

export default {
  created() {
    this.$store.dispatch(GET_DOMAIN_ROLE);
  },
  components: { Add, SeachForm },
  computed: {
    ...mapState({
      data: (state) => state.domain_role.data,
      fetching: (state) => state.domain_role.fetching,
      error: (state) => state.domain_role.error,
      table_columns: (state) => state.domain_role.table_columns,
    }),
  },
  data() {
    return {
      add_visible: false,
    };
  },
  methods: {
    handleAddVisible(visible) {
      this.add_visible = visible;
    },
    async handleDeleteVisible(item) {
      const $store = this.$store;
      const $message = this.$message;

      this.$confirm({
        title: "Confirm",
        content: "Do you want to delete?",
        async onOk() {
          const data = {
            username: item.username,
            product: item.product,
            domain: item.domain,
          };

          const response = await domainRoleApi.deleteRole({ data });
          if (response) {
            $message.success("Delete successfully");
            $store.dispatch(GET_DOMAIN_ROLE);
          }
        },

        onCancel() {},
      });
    },
  },
};
</script>
<template>
  <div>
    <SeachForm />
    <div class="admin-create-button-container">
      <a-tooltip placement="topLeft">
        <template slot="title">
          <span>Create</span>
        </template>
        <a-button
          class="admin-create-button"
          type="primary"
          icon="plus"
          @click="() => handleAddVisible(true)"
        >
          Create</a-button
        >
      </a-tooltip>
    </div>
    <a-table :columns="table_columns" :data-source="data" :loading="fetching">
      <a slot="user_action" href="javascript:;" slot-scope="text, record">
        <a-tooltip placement="top">
          <template slot="title">
            <span>Delete</span>
          </template>
          <a-icon
            type="delete"
            @click="handleDeleteVisible(record)"
            theme="filled"
            style="color: red"
          />
        </a-tooltip>
      </a>
    </a-table>
    <Add v-model="add_visible" :handleVisible="handleAddVisible" />
  </div>
</template>
