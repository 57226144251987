<template>
  <div class="update">
    <a-modal
      :visible="visible"
      title="Update"
      onOk="handleUpdate"
      :closable="false"
      :maskClosable="false"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleUpdate"
          >Update</a-button
        >
      </template>

      <a-form :form="form" @submit="handleUpdate">
        <a-form-item label="Name">
          <a-input
            placeholder="Name"
            v-decorator="[
              'name',
              {
                initialValue: selected_item.name,
                rules: [{ required: true, message: 'Name is not empty!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Url">
          <a-input
            placeholder="Url"
            v-decorator="[
              'url',
              {
                initialValue: selected_item.url,
                rules: [{ required: true, message: 'Url is not empty!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Product">
          <a-select
            v-decorator="[
              'product',
              {
                initialValue: selected_item.product,
                rules: [{ required: true, message: 'Product is not empty!' }],
              },
            ]"
            placeholder="Product"
          >
            <a-select-option key="ngocdung" value="ngocdung">
              Ngọc Dung
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Team">
          <a-select
            v-decorator="[
              'team',
              {
                initialValue: selected_item.team,
                rules: [{ required: true, message: 'Team is not empty!' }],
              },
            ]"
            placeholder="Team"
          >
            <a-select-option key="IT" value="IT">
              IT
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import domainApi from "../../api/domain";
import { mapState } from "vuex";
import { getCurrentUserAdmin } from "../../utils/authentication";
import { GET_ADMIN_DOMAIN } from "../../store/actions";

export default {
  data() {
    return {
      currentUser: getCurrentUserAdmin(),
      loading: false,
      form: this.$form.createForm(this, { name: "update_form" }),
    };
  },

  computed: mapState({}),
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleVisible: Function,
    selected_item: Object,
  },
  methods: {
    onCancel() {
      this.handleVisible(false);
      this.form.resetFields();
    },
    handleUpdate(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            name: values.name,
            url: values.url,
            product: values.product,
            team: values.team,
            created_by: this.currentUser.data_token.username,
          };

          const response = await domainApi.updateDomain(
            this.selected_item.id,
            body
          );
          if (response) {
            this.$message.success(`Update successfully`);
            this.onCancel();
            this.$store.dispatch(GET_ADMIN_DOMAIN);
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
