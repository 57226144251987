<template>
  <div class="add">
    <a-modal
      :visible="visible"
      title="Create"
      onOk="handleAdd"
      :closable="false"
      :maskClosable="false"
      @change="onChangeModal"
    >
      <template slot="footer">
        <a-button key="back" @click="onCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAdd"
          >Create</a-button
        >
      </template>

      <a-form :form="form" @submit="handleAdd">
        <a-form-item label="Username">
          <a-input
            placeholder="username"
            v-decorator="[
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input username!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Domain">
          <a-select
            v-decorator="[
              'domain',
              {
                rules: [{ required: true, message: 'Please select domain!' }],
              },
            ]"
            placeholder="domain"
          >
            <a-select-option v-for="name in domains" :key="name" :value="name">
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Product">
          <a-select
            v-decorator="[
              'product',
              {
                rules: [{ required: true, message: 'Product is not empty!!' }],
              },
            ]"
            placeholder="product"
          >
            <a-select-option
              v-for="product in products"
              :key="product.id"
              :value="product.name"
            >
              {{ product.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable no-console */
import domainRoleApi from "../../api/domain-role";
import { GET_DOMAIN_ROLE } from "../../store/actions";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: "add_form" }),
    };
  },
  computed: {
    ...mapState({
      domains: (state) => {
        const data = state.domain.data.map((item) => item.name);
        return [...new Set(data)];
      },
      products: (state) => state.product.data,
    }),
  },
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    handleVisible: Function,
  },
  methods: {
    onChangeModal() {
      this.form.resetFields();
      this.$emit("change", false);
    },
    onCancel() {
      this.handleVisible(false);
      this.form.resetFields();
    },
    handleAdd(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            username: values.username,
            product: values.product,
            domain: values.domain,
          };

          const response = await domainRoleApi.createRole(body);
          if (response) {
            this.$message.success("Create successfully");
            this.$store.dispatch(GET_DOMAIN_ROLE);
            this.onCancel();
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
