export const ROLES = {
  SUPER_ADMIN: "superadmin",
};

export function logout() {
  // remove user from local storage to log user out
  try {
    localStorage.removeItem("PORTAL_USER");
  } catch (error) {
    console.log(error);
  }
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("PORTAL_USER"));
}

export function isAuthenticated() {
  const user = JSON.parse(localStorage.getItem("PORTAL_USER"));
  if (user === null) return false;
  else return true;
}

export function logoutAdmin() {
  // remove user from local storage to log user out
  try {
    localStorage.removeItem("PORTAL_ADMIN_USER");
  } catch (error) {
    console.log(error);
  }
}

export function getCurrentUserAdmin() {
  return JSON.parse(localStorage.getItem("PORTAL_ADMIN_USER"));
}

export function isAuthenticatedAdmin() {
  const user = JSON.parse(localStorage.getItem("PORTAL_ADMIN_USER"));
  if (user === null) return false;
  else return true;
}
