<script>
import authApi from "../../api/auth";
import { user_routes } from "../../router/routes";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      user_routes,
      loading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          const body = {
            username: values.username,
            password: values.password,
            product: values.product,
          };
          let response = await authApi.login(body);
          if (response) {
            localStorage.setItem("PORTAL_USER", JSON.stringify(response.data));
            window.location.reload();
            setTimeout(() => {
              this.$router.replace(user_routes.main);
              this.loading = false;
            }, 1000);
          } else {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<template>
  <div class="login">
    <a-row>
      <a-col :span="12">
        <img
          src="../../assets/images/bg.svg"
          alt="login image"
          class="login-img"
        />
      </a-col>
      <a-col :span="12">
        <div class="login-section-wrapper">
          <div class="login-wrapper">
            <div class="brand-wrapper">
              <img src="../../assets/images/eggs.svg" alt="logo" class="logo" />
            </div>
            <a-form :form="form" class="login-form" @submit="handleSubmit">
              <a-form-item label="Mã nhân viên">
                <a-input
                  size="large"
                  v-decorator="[
                    'username',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Mã nhân viên không được để trống!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Ví dụ: 1234"
                  autocomplete="username"
                >
                  <a-icon
                    slot="prefix"
                    type="user"
                    style="color: rgba(0,0,0,.25)"
                  />
                </a-input>
              </a-form-item>
              <a-form-item label="Mật khẩu">
                <a-input-password
                  size="large"
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Mật khẩu không được để trống!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Nhập mật khẩu"
                  autocomplete="current-password"
                >
                  <a-icon
                    slot="prefix"
                    type="lock"
                    style="color: rgba(0,0,0,.25)"
                  />
                </a-input-password>
              </a-form-item>

              <a-form-item>
                <a-checkbox
                  v-decorator="[
                    'remember',
                    {
                      valuePropName: 'checked',
                      initialValue: true,
                    },
                  ]"
                  >Remember password</a-checkbox
                >
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  :loading="loading"
                  class="login-button"
                >
                  ĐĂNG NHẬP
                </a-button>
              </a-form-item>
            </a-form>
            <div class="forgot-password">
              <a :href="user_routes.reset_password">
                Quên mật khẩu ?
              </a>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<style>
.login .ant-input {
  font-size: 14px;
}
.login .login-button {
  width: 100%;
  padding: 15px 20px;
  background-color: #021244;
  border-radius: 130px;
  font-size: 16px;
  color: #fff;
  height: auto;
  border-color: #021244;
  font-weight: 700;
}
.login .login-button:hover {
  background-color: #90c156;
}
.login .forgot-password {
  text-align: center;
}

.login .forgot-password a {
  color: #777;
  font-size: 14px;
  display: block;
  margin-bottom: 54px;
}
.login .login-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: left;
}
.login .login-section-wrapper {
  display: flex;
  padding: 68px 100px;
  margin-top: 150px;
}

.brand-wrapper {
  padding-top: 7px;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.brand-wrapper .logo {
  height: 100px;
}

.login-wrapper {
  width: 350px;
  max-width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (max-width: 575px) {
  .login-wrapper {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .login .login-section-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 575px) {
  .login .login-section-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 100vh;
  }
}
/* Responsive */
@media only screen and (max-width: 767px) {
  .login .login-img {
    display: none;
  }
  .login .ant-col-12 {
    width: 350px;
    float: none;
    margin: auto;
  }

  .login .login-section-wrapper {
    padding: 0 30px;
    margin-top: 0;
    height: 100% !important;
    display: flex;
    align-items: center !important;
  }
}
</style>
